.users-container{
	
}
.users-container-head{
	margin: 0% 8%;
}
.users-container-head h2{
	width: auto;
	padding-left: 8px;
}
.users-container-head .header{
	padding: 0px
}
.users-container .card div{
	display: grid;
    justify-content: space-between;
    grid-template-columns: 220px 115px 165px 290px;
    width: 100%;
}
.users-container-head .header div{
	display: grid;
    justify-content: space-between;
    grid-template-columns: 218px 105px 159px 308px;
    width: 100%;
}
.name:hover{
	cursor: pointer;
	filter: brightness(0.5);
}

.filters select{
	margin: 0 !important;
}