#loading {
  width:300px;
  height: 400px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.circle {
  transform: rotate(180deg);
  animation: turn 3s linear infinite;
}

.circle.reverse {
  animation-direction: reverse;
  animation-duration: 1.3s;
  margin-left: -18.5em;
}

@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
