.main {
    padding: 3rem;
    padding-top: 2rem;
    min-height: 100vh;

  }
  
.table{
  width: 100%;
  border-spacing: 0;
}

.tableReportContainer{
    overflow-x: auto;
}

.tableHeader{
    width: 100%;
    border-radius: 20px;

  }

.tableHeader th{
   text-align: left;
   padding: 8px ;
   font-family: "Poppins Regular";
   color: var(--nyc-white-200);
   background-color: #445469 !important;
  }

.tableBody{
    width: 100%;
  }


.tableBody td{
    padding: 8px;  
    font-family: "Poppins Regular";
    color: #000;
}

.lightGreen {
  background-color: #fff !important; 
}

.darkGreen {
  background-color: #4454690d !important;
}

.containerButtonsPagination{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  gap: 16px;

}

.containerFunctions{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  font-size: 14px;
}

.containerFunctions p{
  font-family: 'Poppins Regular';
  color: black;
}

.containerSelect{
	display: flex;
	align-items: center;
}

.selectFilter
{
    color: #6d6d6d;
}

.selectShow{
    color: var(--nyc-green-400);
    margin: 0 6px;
	width: 3rem;
}

.filtersReport input, select{
	margin-top: 8px;
	margin-bottom: 16px;
}

.filtersReport input {
	width: calc(100% - 12px);
}


.buttonGenerate{
	max-width: 130px;
	padding: 0 6px;
	font-family: 'Poppins Regular';
}

.AccButton{
	font-size: small;
	color: var(--nyc-green-400);
	text-align: right;
	font-family: 'Poppins Regular';
	text-decoration: underline;
}

.small{
	max-width: 53px;
}

.nameFunc:hover{
	cursor: pointer;
	background-color: #aae5c8;
}

.name{
	width: 390px;
}

.product{
    width: 238px;
}

.value {
	width: 115px
}

.situation{
	width: 100px;
}

.parcel{
	width: 153px;
}

.currentPage{
	background-color: #545e6b !important;
}
.situation select {
	margin-top: 8px;
}
@media (max-width: 992px )
{
	.containerFunctions{
    align-items: flex-start !important;
    flex-direction: column;
    gap: 8px;
	}
	.filtersReport{
		display: flex;
		flex-direction: column;

	}
	.filtersReport th{
		width: 100% !important;
	}
}
