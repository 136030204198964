.container-grid{
	display: grid;
    grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
	width: 100%;
    gap: 20px;
}
.title{
	display: flex;
    flex-direction: column;
    align-items: center;
}

.container{
	margin-top: 20px;
	margin: 3rem 6rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.clientName{
	color: #07ba01;
	font-size: xx-large;
	margin-bottom: 30px;
	font-family: 'Poppins Regular';
}

.card{
	border-radius: 16px;
	border: 2px solid var(--nyc-green-400);
	padding: 8px;
	display: grid;
	gap: 8px;
	justify-items: center;
	font-family: 'Poppins Regular';
	text-align: center;
}
