header{
	background-color: var(--nyc-green-400);
    color: var(--nyc-white-100);
    padding: 1rem 1rem;
    display: grid;
    grid-template-rows: 75px 1fr;
    justify-content: space-between;
    align-items: flex-start;
    font-family: 'Poppins Regular';
    font-size: 16px;
}

nav{
	display: grid;
	gap: 16px;
	padding-top: 24px;
}

