.container-correspondents{
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 30px;
}

.container-correspondents nav {
	font-family: 'Poppins Regular';
}
.container-correspondents nav a{
	margin-bottom: 4px;
}

.aviso{
	font-family: 'Poppins Regular';
	background-color: white;
	border-radius: 16px;
	padding-left: 10px;
		color: var(--nyc-green-400)
}
