.container-tickets-report{
	display: flex;
	gap: 30px;
	font-family: 'Poppins Regular';
	/* align-items: center; */
}

.cardImg{
	height: 500px;
}

.cardImgCom{
	height: 500px;
	width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 8px;
	border: 2px dashed #c6c6c6;
	border-radius: 16px;
}
.cardImgCom img{
	border-radius: 16px;
	height: 600px;
	width: 220px;
	object-fit: cover;
	/* scale: 0.5; */
	transform: rotate(-90deg);
}

.cardImg img {
height: 480px;
border-radius: 16px;
}

.moscaDemais b {
	color: black                  
}

.grid-card{
	display: grid;
	gap: 1px;
    background-color: #0e4b3e82;
	padding: 1px;
}

.infos{
	font-family: 'Poppins Regular';
}

.grid-dados{
	display: grid;
	margin-left: 16px;
	color: black;
}
.grid-card .card{
	background-color: #f0f0f0;
}

.titleSerasa{
	font-family: 'Poppins Regular';
	color: black;
}

.container-reports{
	display: flex;
}

.container-serasa h2 {
	width: auto;
	font-size: 18px;
	margin-top: 14px;
	margin-bottom: 8px;
}

.container-serasa .grid-card{
	font-size: 15px;
}

.warning{
	font-family: 'Poppins Regular';
	font-size: 16px;
	color: black;
}