.container-correspondents{
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 30px;
}

.container-correspondents nav {
	font-family: 'Poppins Regular';
}
.container-correspondents nav a{
	margin-bottom: 4px;
}
.aviso{
	color: var(--nyc-green-400)
}
.we {
	padding: 0px 8px
}

.clients-container .card div {
	grid-template-columns: 325px 115px 165px 290px !important;
}

.clients-container-head{
	grid-template-columns: 325px 115px 165px 290px !important;
}
