.main {
    padding: 3rem;
    padding-top: 2rem;
    min-height: 100vh;

  }
  
.table{
  width: 100%;
  border-spacing: 0;
}

.tableContainer{
	border-radius: 8px;
	margin: 2%;
    overflow: hidden;
}
.title{
	margin-left: 30px;
	padding-top: 30px;
}


.navContainer{
	margin: 0 2%
}

.navContainer a{
	font-family: 'Poppins Regular';
	font-size: .9em;
}

.navContainer a:hover{
	text-decoration: underline;
}

.link:hover{
	text-decoration: underline;
	cursor: pointer;
}

.tableHeader{
    width: 100%;
    border-radius: 20px;

  }

.tableHeader th{
   text-align: left;
   padding: 8px ;
   font-family: "Poppins Regular";
   color: var(--nyc-white-200);
   background-color: var(--nyc-green-400);
  }

.tableBody{
    width: 100%;
  }


.tableBody td{
    padding: 8px;  
    font-family: "Poppins Regular";
    color: #000;
}

.loading-page-loan{
	width: 100%;
	height: 100%;
	display: flex;
	padding-top: 3em;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
  }
  .loading-page-loan p{
	font-family: 'Poppins Regular';
	color: #b8b8b8;
	font-size: 24px;
	margin-bottom: 3em;
  }

.lightGreen {
  background-color: #15f9872b; 
}

.darkGreen {
  background-color: #15f9870d;
}

.containerButtonsPagination{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  gap: 16px;

}

.containerFunctions{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  font-size: 14px;
}

.containerFunctions p{
  font-family: 'Poppins Regular';
  color: black;
}

.containerSelect{
	display: flex;
	align-items: center;
}

.selectFilter
{
    color: #6d6d6d;
}

.selectShow{
    color: var(--nyc-green-400);
    margin: 0 6px;
	width: 3rem;
}

.filters input, select{
	margin-top: 16px;
}

.filters input {
	width: calc(100% - 12px);
}


.buttonGenerate{
	max-width: 130px;
	padding: 0 6px;
	font-family: 'Poppins Regular';
}

.AccButton{
	font-size: small;
	color: var(--nyc-green-400);
	text-align: right;
	font-family: 'Poppins Regular';
	text-decoration: underline;
}

.small{
	max-width: 53px;
}

.nameFunc:hover{
	cursor: pointer;
	background-color: #aae5c8;
}

.name{
	width: 390px;
}

.product{
    width: 238px;
}

.value {
	width: 115px
}

.situation{
	width: 100px;
}

.parcel{
	width: 153px;
}

.currentPage{
	background-color: #545e6b !important;
}

.first {
	border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.unique{
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	border-bottom-left-radius: 15px !important;
	border-bottom-right-radius: 15px !important;
}

.last
{
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}