.containerCharts{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(436px, 1fr));
    gap: 4rem;
    margin: 6% 6%;
}

.box-title{
	background-color:#445469;
	padding: 8px;
}
.box-title span{
	color: white;
	font-size: 1.5em;
	font-weight: bold;
}

.filter{
	opacity: 0.6;
}

canvas{
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	border-radius: 8px;
}

.container-divisor{
	margin: 0 7%;
	overflow: hidden;
	display: flex;
	/* max-width: 100%; */
}

.custody-container-card{
    grid-gap: 16px;
    background-color: #445469;
    display: grid;
    gap: 1px;
    width: 100%;
    padding: 1px;
}
.custody-container-card .card div{
	display: flex;
	flex-direction: column;
}
.custody-container-card .card h2{
	width: auto;
	margin: 0;
	font-size: 1.1em;
}
.custody-container-card .card .gray, .valor{
	font-size: .9em;
}

.custody h3{
	margin-top: 16px;
	margin-bottom: 0 !important;
}
.custody-grid{
	display: flex;
    gap: 20px;
}

.total-value{
	margin-bottom: 8px;
	color: #fff;
	font-weight: 100;
}

.dataContainer{
	font-family: 'Poppins Regular';
	display: grid;
}
/* .custody-grid{
	display: flex;
} */
.box-chart{
	display: grid;
	grid-template-columns: 1fr 400px;
	gap: 32px;
	align-items: center;
	margin: -4em 0 -3em 0;
}

.dataGrid{
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
	gap: 32px;
	margin-top: 16px;
}
.dataContainer h2{
	font-size: 1.4em;
	margin-bottom: 12px;
}

.dataContainer h3{
	margin-bottom: 6px;
}

.container-dash small b{
	color: black;

}
.dashboard-main{
	width: 100%;
}



.container-dash{
	margin: 2% 7% !important;
	display: flex;
	justify-content: space-between;
	font-family: 'Poppins Regular';
	
}

.dataContainer{
	margin: 0% 7% 2% 7% !important
}

.dash-container{
	margin: 1% 0 !important;
    border: 1px solid #445469;
    padding: 4px;	
	display: grid;
	justify-items: center;
}
.dash-container .card div{
	grid-template-columns: 85px 95px 120px;
}

.dash-container p{
	color: black;
}

.divisor-dash{
    display: flex;
    height: 5px;
    background: linear-gradient(90deg, #017172 0%, #0B766E 14.17%, #61A14D 14.27%, #6BA54A 28.58%, #DBDD1F 28.63%, #DBDD1F 42.81%, #AF4779 42.89%, #AF4878 57.2%, #D1D823 57.21%, #CED624 71.41%, #92B93A 71.47%, #7FAF42 85.72%, #1A7D68 85.8%, #017071 100%);
    border: none;
    opacity: 1;
}

.containerFilters{
	display: flex;
	justify-content: space-between;
	margin-top: 8px;
	gap: 6px;
}

.containerFilters button{
	width: fit-content;
	padding: 0 30px
}

.container{
	max-width: 100%;
	padding: 2rem;
}

.dash-container-head{
	margin: 0%;
}
.dash-container-head .header{
	padding: 0;
}
.dash-container-head .header div{
	display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
    font-size: .8em;
    width: 100%;
}

b{
	font-family: 'Poppins Regular';
    text-align: left;
    justify-self: left;
    color: #0e4b3e;
	font-size: 1.1em;
}
