.card{
	background-color: whitesmoke;
	display: grid;
	border: none;
	border-radius: 0;
}

.card div{
    display: grid;
    justify-content: space-between;
    grid-template-columns: 60px 231px 80px 341px 173px;
    width: 100%;
}

.container-card{
    grid-gap: 16px;
    background-color: #0e4b3e82;
    display: grid;
    gap: 1px;
    margin: 1% 8%;
    padding: 1px;
	border-radius: 16px;
}

.first {
	border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}


.last
{
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}
.container-title{
    margin: 20px 8%;
}

.filters {
	padding-right: 0;
}
.filters input, select {
	margin-top: 0;
}

.container-filters{
	margin: 0 8%
}

.valor{
    font-family: 'Poppins Medium';
}

.gray{
	font-family: 'Poppins Regular';
	color: rgb(164, 164, 164);
}

.debit .valor {
	
	color: rgba(104, 0, 0, 0.846);
}

.actual-value{
	font-family: 'Poppins Regular';
    color: rgb(99 99 99);
}

.date{
	font-family: 'Poppins Regular';
    color: rgb(99 99 99);
}

.credit .valor{ 
	color: rgb(3 104 0);
	padding-left: 7px;
}
.debit{ 
	justify-self: flex-end;
}
.credit{ 
	justify-self: flex-start;
}

.card{
	display: grid;
	justify-items: flex-start;
	text-align: left;
	width: 100%;
	padding-left: 16px;
}

.containerButtonsPagination{
	margin-bottom: 30px;
}

.none{
	display: none !important;
  }
  
  .flex{
	  display: flex;
  }

h2{
	margin: 0;
	width: 60px
}

/* @media (max-width: 1200px) {
	.container-card{
		width: 832px;
		overflow-x: auto;
	}
} */