.input{
    height: 43px;
    border-radius: 16px;
    border: none;
    outline: none;
    font-size: 14px;
    padding-left: 12px;
    border: 1px solid #cdcdcd;
    color: #000000;
    background-color: #fff;
}

.input::placeholder{
    font-size: 14px;
    color: #6d6d6d;
}