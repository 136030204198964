.qrcode{
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 15px;
	font-family: 'Poppins Regular';
}
.imgNyc{
	display: flex;
	align-items: center;
	flex-direction: column;
}

.qrcodeInfo {
	width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 16px;
}

.modal{
    position: absolute;
    inset: 35%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    height: 165px;
    width: 400px;
    display: grid;
    gap: 32px;
}

.modalDate {
    position: absolute;
    inset: 35%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    height: 200px;
    width: 450px;
    display: grid;
    gap: 10px;
}
.modalPayed{
	position: absolute;
    inset: 35%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    height: 280px;
    width: 450px;
    display: grid;
    gap: 10px;
}

.buttons{
	display: flex;
	gap: 8px;
}
.no{
	background-color: var(--nyc-red);
}
.no:hover{
	background-color: #c70000;
}
/* .Button{
	display: flex;
    flex-wrap: nowrap;
    align-items: center;
	width: 100px;
	margin-right: 20px;
} */
.Buttons{
	display: grid;
	gap: 8px;
}
.paymentstats{
	color: #07ba01;
}
.clientName{
	color: #07ba01;
	font-size: xx-large;
	margin-bottom: 20px;
}
li{
	display: grid;
	grid-template-columns: 1fr .8fr;
    align-items: center;
	border: 1px solid #0e4b3e;
	border-radius: 16px;
	gap: 8px;
	padding: 20px;
	font-family: 'Poppins Regular';
	color: black;
}
.filters{
	display: flex;
	width: 100%;
	align-items: flex-end;
	justify-content: flex-end;
	gap: 16px;
	margin: 16px 0px;
	padding-right: 16px;

}
.reverse{
    max-width: fit-content;
    padding: 0px 30px;
}
.filters div{
	display: grid;
}
.filter p {
	color: black;
}
[alt="logo da NYC Bank"]{
	width: 250px;
	height: auto;
	margin-top: 24px;
	margin-bottom: 24px;
	align-items: center;
}
.container-check{
	display: flex;
	align-items: center;
	gap: 8px;
}
.container-check label{
	color: black;
}

.check{
	height: 35px;
}

.reversed{
	color: var(--nyc-red);
	font-weight: 700;
}

.green{
	color:#07ba01
}

.red{
	color: var(--nyc-red);
}
