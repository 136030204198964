.SignInContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: "Poppins Regular";
	margin-top: 150px;
	width: 100%;
}
.SignIn{
	width: 500px;
	gap: 8px;
	display: grid;
	font-family: "Poppins Regular";
}
.AccButton{
	font-size: small;
	color: var(--nyc-green-400);
	text-align: right;
	font-family: 'Poppins Regular';
	text-decoration: underline;
}
.SignInPage{
	display: flex;
	align-items: center;
}
.passwordContainer{
	display: flex;
	justify-content: space-between;
	gap: 12px;
}

.passwordContainer input{
	width: 100%
}


[alt="logo da NYC Bank"]{
	width: 250px;
	height: auto;
	margin-bottom: 24px;
}


.disabled{
	opacity: 0.6;
}
.able{
	opacity: 1;
}