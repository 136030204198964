.select{
    height: 43px;
    border-radius: 16px;
    border: none;
    outline: none;
    text-align: center;
    font-size: 14px;
    font-family: 'Poppins Regular';
    border: 1px solid #cdcdcd;
    background-color: #fff;
    color: #6d6d6d;
}

