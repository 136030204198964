 .button {
	color: white;
	background-color: var(--nyc-purple);
	height: 45px;
	border: none;
	border-radius: 16px;
	font-size: 17px;
  }
  
  .input {
	height: 45px;
	border: 1px solid var(--nyc-gray);
	border-radius: 16px;
	font-size: 17px;
	padding-left: 12px;
  }

  .principal {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 64px;
	margin: 20px 0px;
	justify-content: center;
  }
  
  .tableLoan aside {
	display: grid;
	grid-template-rows: 30% auto;
  }

  .container{
	background-color: #efefef;
    padding: 8% 16%;
  }
  
  #ticket-page {
	overflow-x: auto; 
	margin-bottom: 24px;
	padding: 20px 40px;
	background-color: white;
  }
  
  .main-header {
	display: grid;
	margin: 12px;
	/* gap: 12px; */
  }
  
  .main-header > h1 {
	color: var(--nyc-green-200);
	font-family: "Poppins Regular";
	font-size: 1.5em;
  }
  
  .main-header > p {
	font-size: 1.1em;
  }
  
  .oblong-balance-container,
  .rectangle-due-date {
	background-color: #f0f4f7;
	display: grid;
	grid-template-columns: auto 1fr;
	border-radius: 50px;
	padding: 15px 20px;
	width: 100%;
	font-family: 'Poppins Regular';
	margin-top: 24px;
  }
  .oblong-balance-container p {
	 color: black;
  }
  
  [alt="dollar-sign"],
  [alt="calendar-icon"] {
	background-color: var(--nyc-green-200);
	border-radius: 45%;
	width: 52px;
	height: auto;
	padding: 10px;
	grid-row: 1/3;
	align-self: center;
  }
  
  .oblong-balance-container > p,
  .rectangle-due-date > p {
	font-size: 18px;
	padding-left: 8px;
	align-self: center;
  }
  
  .description-container > div > p::first-child {
	color: var(--nyc-green-200);
	font-size: 20px;
  }
  
  .intermediated {
	color: var(--nyc-gray);
	font-size: 17px;
	margin-top: 8px;
  }
  
  [alt="NYC BANK logo"] {
	height: 70px;
	width: fit-content;
  }
  
  [alt="calendar icon"] {
	width: 33px;
	height: auto;
  }
  
  [alt="cellphone camera"],
  [alt="qr code reader"] {
	width: 27px;
	height: auto;
  }
  
  .qr-code-container {
	display: grid;
    grid-template-rows: auto auto 60%;
    border-radius: 5px;
    padding: 16px;
    border: 5px solid #f0f4f7;
    width: fit-content;
    margin: 30px;
  }
  
  .description-container {
	display: grid;
	padding: 30px 0px 30px 30px;
	height: auto;
	align-self: center;
	height: 450px;
  }
  
  .taxes {
	display: flex;
	flex-direction: column;
	height: 150px;
  }

  #qr-code-balance{
	color: black;
  }

  .rectangle-due-date p {
	color: black;
  }
  
  #after-payment {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
  }
  
  #taxes-fine,
  #taxes-fees,
  #taxes-month {
	height: auto;
	display: block;
  }
  
  #taxes-fine p::first-child {
	color: black;
  }
  
  #taxes-fees p::first-child {
	color: black;
  }
  
  #taxes-month p::first-child {
	color: black;
  }
  
  .taxes p {
	grid-column: 1/3;
  }
  
  .taxes-content {
	display: flex;
  }
  
  .taxes-explanation {
	color: var(--nyc-gray);
  }
  
  .instruction-text {
	display: flex;
	gap: 17px;
	align-items: center;
	justify-self: center;
	width: 90%;
	font-family: 'Poppins Regular';
	font-size: 16px;
	color: #b8b8b8
  }
  
  #payment-qr-code-img {
	display: grid;
	padding-bottom: 12px;
  }

  .loading-page{
	width: 100%;
	height: 100%;
	display: flex;
	padding-top: 3em;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
  }
  .loading-page p{
	font-family: 'Poppins Regular';
	color: #b8b8b8;
	font-size: 24px;
	margin-bottom: 3em;
  }
  
  #payment-qr-code-img img {
	height: 90%;
	justify-self: center;
  }
  .link-bar {
	grid-column: 1/3;
	text-align: center;
	font-size: 17px;
	background-color: #f0f4f7;
	font-family: 'Poppins Regular';
  }
  
  .link-bar p {
	padding: 12px 0;
	font-size: 15px;
  }
  
  .link-bar > p > a {
	color: var(--nyc-green-200);
  }
  
  hr {
	border: none;
	border-bottom: 1px dotted #000;
  }
  
  .footer-header {
	display: flex;
	align-items: center;
	padding: 10px 0px;
  }
  
  .footer-header p{
	font-family: 'Poppins Semibold';
	padding: 0px 4px;
  }
  .footer svg{
	width: 100%;
  }
  
  [alt="NYC bank logo clean"] {
	height: 15px;
	padding-right: 4px;
  }
  
  .tableLoan {
	font-size: 9px;
	padding: 2px;
	width: 100%;
	font-family: 'Poppins Regular';
	color: black;
	border-color: black;
  }

  .tableLoan p {
	color: black;
  }

  #recipient-name, #payment-local, #recipient-taxpayer, #doc-number, #doc-date, #doc-payment-type, #doc-accept, #bank-value-doc, #payment-date, #recipient-agency, #doc-our-number{
	font-size: 11px;
  }

  #payment-date, #recipient-agency, #doc-our-number, #bank-value-doc{
	text-align: right;
	font-size: 12px;
	padding-right: 6px;
  }
  
  .tableLoan td {
	height: 35px;
  }
  
  .tableLoan {
	border-collapse: collapse;
	border-left: 1px solid;
	border-bottom: 1px solid;
	border-spacing: 0;
  }
  
  #tr5 {
	border-collapse: collapse;
	border-bottom: 1px solid;
  }
  
  #tr5 td {
	border-collapse: collapse;
	border-left: 1px solid;
	border-spacing: 0;
  }
  
  #tr5 td::first-child {
	border-right: none;
	border-left: none;
  }
  
  #tr6 td {
	border: none;
  }
  #tr6 {
	border-right: 1px solid;
  }
  
  .tableLoan td {
	border-right: 1px solid;
	border-top: 1px solid;
	border-collapse: collapse;
	border-spacing: 0;
  }
  
  .tableLoan td p::first-child {
	color: var(--nyc-gray);
	font-size: 10px;
  }
  
  .tableLoan tr {
	display: grid;
	grid-template-columns: 60% auto;
  }
  
  .tableLoan tr td p {
	padding: 2px;
  }
  
  #tr2 {
	display: grid;
	width: 100%;
	grid-template-columns: 40% 20% 40%;
  }
  
  #tr3 {
	display: grid;
	width: 100%;
	grid-template-columns: 15% 15% 15% 15% 40%;
  }
  
  #tr4 {
	display: grid;
	width: 100%;
	grid-template-columns: 12% 12% 12% 12% 12% 40%;
  }
  
  #tr5 td::first-child {
	grid-row: 1/6;
  }
  
  #tr6 {
	display: grid;
	width: 100%;
	grid-template-columns: 100%;
	height: 65px;
  }

  #name-person{
	font-family: 'Poppins Regular';
	color: black
  }
  
  #tr6 #name-person-table {
	font-size: 15px;
  }
  
  #tr6 p::last-child {
	padding-top: 12px;
  }
  
  .last-p {
	display: flex;
	justify-content: flex-end;
	color: var(--nyc-gray);
	font-size: 12px;
	padding-bottom: 20px;
  }
  
  @media print {
	* {
	  margin: 0 !important;
	  padding: 0 !important;
	}
	#controls,
	.footer,
	.footerarea {
	  display: none;
	}
  
	html,
	body {
	  break-inside: avoid;
	}
  }
  
  #generate_img {
	justify-self: center;
	width: 20%;
	border: none;
	height: 43px;
	border-radius: 16px;
	font-size: 17px;
	background-color: #8753f0;
	color: white;
  }

  h1{
	color: var(--nyc-green-200);
	font-family: 'Poppins Regular';
  }

  .description-container h1{
	color: var(--nyc-green-200);
    font-family: 'Poppins Regular';
    font-size: larger;
    font-weight: 300;
  }
  .description-container p{
    font-family: 'Poppins Regular';
    font-size: 15px;
	color: var(--nyc-gray);
  }
  .description-container span{
	color: black;
  }
  
  #before-payment, #description, #recipient-nyc{
	color: black;
  }