.SearchContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: "Poppins Regular";
	margin-top: 150px;
}
.Search{
	width: 500px;
	gap: 8px;
	display: grid;
	font-family: "Poppins Regular";
}
.primaryButton{
	width: 100%;
}
.AccButton{
	font-size: small;
	color: var(--nyc-green-400);
	text-align: right;
	font-family: 'Poppins Regular';
	text-decoration: underline;
	margin-top: 16px;
}
.LoanSearch{
	display: flex;
	align-items: center;
}
[alt="logo da NYC Bank"]{
	width: 250px;
	height: auto;
	margin-bottom: 24px;
}
