.SignUpContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: "Poppins Regular";
	margin-top: 150px;
}
.SignUp{
	width: 500px;
	gap: 8px;
	display: grid;
	font-family: "Poppins Regular";
}

.primaryButton{
	width: 100%;
}
.login{
	scale: 0.5;
	margin-bottom: 0 !important;
	margin-top: -50px;
}
.AccButton{
	font-size: small;
	color: var(--nyc-green-400);
	text-align: right;
	font-family: 'Poppins Regular';
	text-decoration: underline;
}
.SignUpPage{
	display: flex;
	align-items: center;
}

.passwordContainer{
	display: flex;
	justify-content: space-between;
	gap: 12px;
}

.passwordContainer input{
	width: 100%
}

[alt="logo da NYC Bank"]{
	width: 250px;
	height: auto;
	margin-bottom: 24px;
}

.Button{
	width: 100%;
}

@media (max-width: 600px) {
	.SignUp{
		width: 80%
	}
}