.primaryButton{
    height: 43px;
    width: 100%;
    border: none;
    border-radius: 16px;
    background-color: #445469;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.primaryButton:hover{
    background-color: #545e6b;
	cursor: pointer;
}

.none{
  display: none !important;
}

.flex{
    display: flex;
}