.iconButton{
    height: 43px;
    width: 50px;
    border: none;
    border-radius: 16px;
    background-color: #445469;
    color: #fff;
}

.iconButton:hover{
	cursor: pointer;
}