.aprrovedContainer{
	font-family: 'Poppins Regular';
    color: #717171;
	display: grid;
	justify-content: center;
	margin-top: 30px;
	margin-bottom: 30px;
	width: 100%;
	overflow-y: scroll;

}

.container-border{
    justify-content: center;
    border: 2px solid #5abb02;
    width: fit-content;
    align-items: center;
    padding: 20px;
	margin-bottom: 20px;
	padding-bottom: 50px;
	border-radius: 16px;
}

.approved-grid{
	display: grid;
	justify-items: center;
	width: 100%;
	gap: 16px;
}
.approved-grid p{
	width: 100%;
}
.divisor{
	width: 100%;
}

.divisor h3 {
	margin-bottom: 8px;
	font-size: 20px;
}

.container-tickets{
	display: grid;
	grid-template-columns: 320px 320px;
	gap: 8px;
}

.moscaDemais{
	height: 100%;
	text-align: center;
}
.moscaDemais b{
	margin-bottom: 8px;
	display: flex;
}

.container-tickets img{
	width: 100%;
	border-radius: 18px
}

.cardImg {
	display: flex;
	text-align: center;
	align-items: center;
	padding: 8px;
	border: 2px dashed #c6c6c6;
	border-radius: 16px;
	height: 100%;
}

.gird{
	display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(198px, 1fr));
}

hr{
	border: 1px solid #717171;
	opacity: 0.3;
	width: 100%;
}

.header{
	display: flex;
	justify-content: flex-start;
	gap: 50px;
	padding: 20px 0px;
}
.header p{
	text-align: left;
}